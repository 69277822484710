@page {
    size: 6.18in x 11in;
}

@media print {
   .section {
    height: 6.18 in;
    width: 11in;
    break-before: always;
    display: block !important;
   }

   .section.slideshow-section {
    height: auto !important;
   }
   .slideshow-section .fp-tableCell, .slideshow-section .h-screen {
    height: auto !important;
   }

   .print-image {
    opacity: 1 !important;
    transform: none !important;
   }

   .print-hide-title {
    display: none !important;
   }

   .slideshow-container, .slideshow-containter > .aspect-video {
        padding: 0 !important;
        height: auto !important;
        aspect-ratio: auto !important;
   }

   .swiper-wrapper {
        display: flex !important;
        flex-direction: column !important;
   }

   .swiper-button-prev, .swiper-button-next {
        display: hidden;  
   }
}
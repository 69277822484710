#diversity-images {
    animation: slide 20s infinite alternate ease-in-out;
}



/* @keyframes slide {
    0% {
        transform: translateX(50%);
    }
    100% {
        transform: translateX(-50%);
    }
} */

#diversity-images div img {
    animation: float 10s infinite alternate ease-in-out;
}

#diversity-images div:nth-of-type(3n+1) img {
    animation-duration: 12s;
}

#diversity-images div:nth-of-type(2n+1) img {
    animation-duration: 7s;
}

#diversity-images div:nth-of-type(2n+1) img {
    animation: floatsmall 10s infinite alternate ease-in-out !important;
}

#diversity-images div:nth-of-type(2n+1) {
    transform: translateX(25%);
}

#diversity-images div:nth-of-type(5n) {
    transform: translateX(-12.5%);
}

#diversity-images div:nth-of-type(3n+1) {
    transform: translateX(-15%);
}
#diversity-images div:nth-of-type(7n) {
    transform: translateX(8%);
    animation: float 13s infinite alternate ease-in-out;
}


@keyframes float {
    0% {
        transform: translateY(0) scale(1.2);
    }
    100% {
        transform: translateY(-25%) scale(1);
    }
}

@keyframes floatsmall {
    0% {
        transform: translateY(0) scale(0.7);
    }
    100% {
        transform: translateY(-25%) scale(0.9);
    }
}

.section:not(.active) #diversity-images, .section:not(.active) #diversity-images *, .section:not(.active) #diversity-images img {
    animation-play-state: paused !important;
}

.swiper-slide:not(.swiper-slide-active) #diversity-images, .swiper-slide:not(.swiper-slide-active) #diversity-images *, .swiper-slide:not(.swiper-slide-active) #diversity-images img {
    animation-play-state: paused !important;
}


#feature-article {

  @apply transition-all duration-1000 ease-in-out ;
  max-width: min(50vw, 500px);
  width: 100%;

  &.closed {
    @apply p-0 transform translate-x-full max-w-0;
  }
  // h1 {
  //   @apply text-5xl font-black mb-6 text-neutral-100;
  // }
  // h2 {
  //   @apply text-2xl font-light mb-4 leading-relaxed text-neutral-100;
  // }
  // h3 {
  //   @apply font-extrabold mb-2 mt-6 uppercase text-neutral-100;
  // }

  // p {
  //   @apply text-lg last:mb-8 mb-4 font-normal leading-relaxed text-neutral-100;
  // }


  h1 {
    @apply text-white text-4xl font-bold leading-tight mt-2 mb-4;
  }
  
  h2 {
    @apply text-white text-3xl font-semibold leading-tight mt-2 mb-4;
  }
  
  h3 {
    @apply text-white text-2xl font-semibold leading-tight mt-2 mb-4;
  }
  
  h4 {
    @apply text-white text-xl font-semibold leading-tight mt-2 mb-4;
  }
  
  h5 {
    @apply text-white text-lg font-semibold leading-tight mt-2 mb-4;
  }
  
  h6 {
    @apply text-white text-base font-semibold leading-tight mt-2 mb-4;
  }
  
  p {
    @apply text-white mt-0 mb-4;
  }


  ol {
    @apply list-outside list-decimal ml-8 mb-6 text-neutral-100;
    li {
        @apply mb-2 text-neutral-100;
    }
  }
  
  a {
    @apply text-blue-500 underline;
  }
  
  strong {
    @apply font-bold;
  }
  
  em {
    @apply italic;
  }

}

.video-modal::-webkit-media-controls-panel {
  background-image: linear-gradient(transparent, #12121299, #121212ff) !important;
  height: 100% !important;
  transition: all 0.25s ease !important;
} 

#feature-article:not(.closed) .hamburger > span {
  @apply translate-y-1.5 rotate-45
}

#feature-article:not(.closed) .hamburger > span:nth-of-type(2) {
  @apply -translate-y-1.5 -rotate-45 w-8
}
.monthly-hero-card {
    @apply relative rounded-3xl overflow-hidden bg-neutral-800;
    mask-image: linear-gradient(white, black);
    -webkit-mask-image: -webkit-radial-gradient(white, black);
}

body.stop .highcharts-tooltip-container {
    z-index: 1110 !important;
}

.modal .section {
    @apply w-full;
}

.monthly-swiper .swiper-slide {
    @apply brightness-50 transition duration-300 ease-in-out scale-75 blur-sm;
}

.monthly-swiper .swiper-slide-active {
    @apply brightness-100 scale-100 blur-none;
}

.monthly-swiper .swiper-button-next {
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items:center;
    width: 10%;
    background-image: linear-gradient(90deg, rgba(0,0,0,0), rgba(0,0,0,0.6) 50%, rgba(0,0,0,0.89));
    background-size: 200%;
    background-color: transparent;
    pointer-events:auto;
    border-radius: 0;
    user-select: none;
    transition: background-size 0.3s ease-in-out;
}

.monthly-swiper .swiper-button-next::after, .monthly-swiper .swiper-button-prev::after {
    font-size: 60px;
    font-weight:400;
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
}

.monthly-swiper .swiper-button-next:hover, .monthly-swiper .swiper-button-prev:hover {
    background-size: 100%;
}

.monthly-swiper .swiper-button-next:hover::after, .monthly-swiper .swiper-button-prev:hover::after {
    opacity: 1;
}

.swiper-button-lock {
    visibility: hidden;
}

.monthly-swiper .swiper-button-prev {
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items:center;
    width: 10%;
    background-image: linear-gradient(to left, rgba(0,0,0,0), rgba(0,0,0,0.6) 50%, rgba(0,0,0,0.89));
    background-size: 200%;
    background-position: right;
    background-color: transparent;
    transform: unset;
    pointer-events:auto;
    border-radius: 0;
    user-select: none;
    transition: background-size 0.3s ease-in-out;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.list-blue-disc li {
    position: relative;
}

.list-blue-disc li::before {
    content: "•";
    display: block;
    position: absolute;
    left: -1rem;
    color: #0088ff;
    border-radius: 50%;
    margin-right: 0.5rem;
}

.bg-teal-950 .list-blue-disc li::before {
    color: theme('colors.teal.500');
}
.bg-red-950 .list-blue-disc li::before {
    color: theme('colors.red.500');
}

.bg-orange-950 .list-blue-disc li::before {
    color: theme('colors.orange.500');
}
.bg-teal-950 .list-blue-disc li::before {
    color: theme('colors.teal.500');
}
.bg-cyan-950 .list-blue-disc li::before {
    color: theme('colors.cyan.500');
}

.monthly-swiper:not(.no-w-auto) .swiper-slide {
    @apply !w-auto;
}

.learn-more {
    @apply inline-block text-white font-extrabold text-lg ;
}
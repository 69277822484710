.monthly-tag {
    @apply text-xl 2xl:text-3xl font-normal mb-2
}
.monthly-title {
    @apply text-3xl 2xl:text-6xl font-black dark:text-white;
    font-family: "Lato", "sans-serif";
}

.star-gradient, .star-gradient path {
    fill: url(#star-gradient);
    color: url(#star-gradient);
}

.star-gradient {
    filter: drop-shadow(0 0px 50px rgba(255, 241, 153, 0.2))
}

.swiper-pagination-bullet {
    @apply bg-black opacity-40
}

.swiper-pagination-bullet-active {
    @apply bg-white opacity-100
}

.swiper-button-prev, .swiper-button-next {
    @apply bg-neutral-900 rounded-full text-white;
    bottom: unset; 
    top: 20px;
    right: 20px;
    height: 30px;
    width: 30px;
    --swiper-navigation-size: 10px;
    font-weight:900
}

.swiper-button-prev {
    left: unset;
    transform: translateX(-140%)
}

.story {
    & h1 {
        @apply text-3xl 2xl:text-6xl font-black dark:text-white mb-12;
        font-family: "Lato", "sans-serif";
    }
    & h2 {
        @apply text-2xl 2xl:text-4xl font-black dark:text-white mb-6 mt-12;
        font-family: "Lato", "sans-serif";
    }
    & h3 {
        @apply text-xl 2xl:text-3xl font-black dark:text-white;
        font-family: "Lato", "sans-serif";
    }
    & h4 {
        @apply text-lg 2xl:text-2xl font-black dark:text-white;
        font-family: "Lato", "sans-serif";
    }
    & h5 {
        @apply text-base 2xl:text-xl font-black dark:text-white;
        font-family: "Lato", "sans-serif";
    }
    & h6 {
        @apply text-sm 2xl:text-lg font-black dark:text-white;
        font-family: "Lato", "sans-serif";
    }
    & p {
        @apply text-base 2xl:text-xl font-normal dark:text-white mb-4;
        font-family: "Lato", "sans-serif";
    }
    & ul {
        @apply text-base 2xl:text-xl font-normal dark:text-white mb-4 list-disc;
        font-family: "Lato", "sans-serif";
    }
    & li {
        @apply text-base 2xl:text-xl font-normal dark:text-white mb-4 ml-6;
        font-family: "Lato", "sans-serif";
    }
}
.modal-container {
    @apply backdrop-filter backdrop-blur-xl;
    position: fixed;
    top:0;
    left:0; 
    bottom:0;
    right:0;
    z-index: 1100;
    /* padding: 50px; */
    display:block;
    background-color: #050608e5;
    background-image: url('../assets/images/noise.png');
    background-blend-mode:hard-light;
    opacity: 0;
    /* animation: fade-in 1s ease forwards; */
}



@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.modal {
    /* width: 100%; */
    /* max-height: 80%;*/
    max-width: 100vw !important;
    padding: 50px;
    position: relative;
    transform: scale(0.7);
    opacity: 0;
    animation: scale-in 0.4s ease forwards;
    background: transparent;
    min-height:100vh;
}

@media (max-width: 1440px) {
    .modal {
        max-width: 1000px;
    }
}

.modal.video {
    /* padding-top: 56.25%; */
    /* max-width: 1600px; */
    /* width: unset; */
    max-height:100%;
    max-width: 100%;
    /* height: 100%; */
    /* display:flex; */
    aspect-ratio: 16/9;
}

.modal-video {
    position: relative;
}

.modal.full {
    /* width: calc(100vw - 100px); */
    max-width: 100vw;
    margin: 0 auto;
    /* height: 100vh; */
    margin: 0 auto;
}
@keyframes scale-in {
    0% {
        transform: scale(0.9);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.modal iframe {
    /* max-width: 1440px; */
    margin: 0 auto;
    height: 100%;
    width: 100%;
    border: none;
    position: absolute;
}

button.toggle-button {
    position: fixed;
    top: 30px;
    right: 30px;
    height: 40px;
    width: 40px;
    border-radius: 50px;
    display: flex;
    flex-direction: row; 
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #f76120;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.25s ease;
    font-size:28px;
    color: white;
     box-shadow: 0 0 4px rgba(0,0,0,0.9);
    z-index: 10;
    line-height: 0;
}


button.toggle-button.video-close-button {
    bottom: -50px;
    top: unset;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    content: 'Close Video'
}

button.toggle-button.video-close-button:hover {
    transform: translate(-50%, -2px);
}

button.toggle-button:hover {
    background-color: #b94615;
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 2px 8px 0px rgba(0,0,0,1);
}


.hide {
    display: none;
}

.MuiFormControl-root {
    margin: 10px !important;
}

.modal iframe, .modal video {
    border-radius: 5px; 
    overflow: hidden;
}

.modal video {
    display: block;
    /* height: 100%; */
    max-height:100%;
    max-width: 100%;
    margin: 0 auto;
}

.modal.img {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.modal.img img {
    max-height: 100%;
    max-width: 100%;
    object-fit:contain;
}

.close {
    position: absolute;
    top:0;
    left:0;
    height:100%;
    width: 100%;
    z-index:-1;
}

#modal img {
    /* height: unset; */
}
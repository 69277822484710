.fp-overflow {
    @apply min-h-screen flex flex-col items-center justify-center overflow-hidden max-h-none lg:max-h-screen
}

.section {
    @apply lg:overflow-hidden;
    /* overflow: hidden */
}

.fp-watermark {
    display: none;
}

.h2 .emphasis {
    font-family: "ivypresto-display", serif;
    font-weight: 700;
    font-style: italic;
}

.text-stroke {
    -webkit-text-stroke: 1px theme('colors.neutral.800');
    text-stroke: 1px theme('colors.neutral.800');
    -webkit-text-fill-color: transparent;
}

.dark .text-stroke {
    -webkit-text-stroke: 2px theme('colors.neutral.100');
    text-stroke: 2px theme('colors.neutral.100');
}

.video-left {
    left: max(calc(-50vw + 80px + 50%), calc(-50vh * 16/9 + 200px + 50%));
    top: max(calc(-50vh + 51%), calc(-50vw * 9/16 + 51%))
}

.marquee-row {
    animation: marquee 45s linear infinite;
    animation-play-state: paused;
    width: max-content;
}


.marquee-row-2 {
    animation: marquee-reverse 45s linear infinite;
    animation-play-state: paused;
    width: max-content;
}

.section.active .marquee-row, .section.active .marquee-row-2 {
    animation-play-state: running;
}

@keyframes marquee {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

@keyframes marquee-reverse {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(50%);
    }
}

.fp-tableCell {
    display:block;
}
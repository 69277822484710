.swiper-slide.perspective {
    perspective: 50vw;
}

.card-container, .inner, .back, .front {
    transform-style: preserve-3d;
    backface-visibility: visible !important;
}

.card-container {
    perspective: 30vw;
}

.video-row {
    animation: slide-row 180s cubic-bezier(.1,0,.9,1) alternate;
    animation-play-state: paused;
}

section.active .video-row {
    animation-play-state: running;
}

@keyframes slide-row {
    100% {
        transform: translateX(-100%);
    }
}

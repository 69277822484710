.lazy-image {
    transition: filter 1s, transform 0.5s ease-in-out;
  }
  
  .lazy-image.blur {
    filter: blur(20px);
  }
  
  .lazy-image.visible {
    filter: blur(0);
  }
.chart-in {
    animation: chartin 3s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

@keyframes chartin {
    0% {
        max-width: 0%; 
        min-width: 0px;
    }
    100% {
        max-width: 100%;
        min-width: 1px;
    }
}

p {
    font-feature-settings: "liga" 0;
}

.newmonthly-tag {
    @apply text-base 2xl:text-xl font-bold mb-4
}
.newmonthly-title {
    @apply text-2xl 2xl:text-5xl font-extrabold mb-10 dark:text-white;
    font-family: "Lato", "sans-serif";
}

.new-border {
    position: relative;
    overflow:visible;
}

.new-border::before {
    content: "";
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:-1px;
    pointer-events:none;
    z-Index: 0;
    border-radius: inherit;
    background:  linear-gradient(to bottom right, rgba(255,255,255,0.2), transparent 30%, rgba(255,255,255,0.1) 69%, rgba(255,255,255,0.05) );
}

.new-linear {
    /* background: linear-gradient(to bottom, #4C5A6340, #4C5A6310); */
    /* background: linear-gradient(to bottom, #00538640, #00325110); */
    background: linear-gradient(to bottom, #245d8140, #173a5010);
    /* border: 2px solid #ffffff20; */
    border: 2px solid #81ddff20;
    border-bottom: 0px;
    border-right: 0px;
}

.highcharts-tooltip-container {
    z-index: 999 !important;
}

.complaints-chart-container > div, .complaints-chart-container .highcharts-container, .complaints-chart-container svg {
    overflow: visible !important;
}

.fade-in {
    animation: fadeIn 0.25s ease-in forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.swiper-button-prev, .swiper-button-next {
    user-select: none;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  border-radius: 0px;
  background: rgba(255,255,255,0.5);
}
::-webkit-scrollbar-thumb{
  border-radius: 10px;
  background: rgba(255,255,255,0.75);
}
::-webkit-scrollbar-thumb:hover{
  background: rgba(0,0,0,0.4);
}
::-webkit-scrollbar-thumb:active{
  background: rgba(0,0,0,.9);
}

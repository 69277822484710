@font-face {
	font-family: 'Font Awesome 5 Pro';
  font-display: "swap";
	src: url('./static/assets/fonts/fa-solid-900.woff2') format('woff2');
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight: 400;
  overflow-y: overlay;
  overflow-x: hidden;
}

.stop {
  @apply overflow-hidden;
  /* padding-right:10px; */
}

.scrollhide {
  display: hidden;
}

.stop .scrollhide {
  display:block;
  background: #8C8F92;
  width: 10px;
  outline: none;
  resize: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
}

.h1 {
  @apply relative z-10 text-neutral-800 dark:text-neutral-200 font-extrabold text-2xl lg:text-5xl 2xl:text-7xl leading-[1.1];
  font-family: 'Montserrat', sans-serif;
}

.h2 {
    @apply relative z-10 text-neutral-800 dark:text-neutral-200 font-extrabold text-4xl 2xl:text-6xl leading-[1.1];
    font-family: 'Montserrat', sans-serif;
}

.h3 {
    @apply relative z-10 text-neutral-800 dark:text-neutral-200 font-extrabold text-2xl 2xl:text-3xl;
}

.highlight2 {
  position: relative;
  /* white-space: pre; */
  color: white;
  text-align:center;
  display: inline-block
}
.highlight2:after {
  content: '';
  position: absolute; 
  top: -0.25rem;
  left:-1em;
  right:-1em;
  bottom:-0.25em;
  transform: skew(-20deg) scaleX(1);
  opacity: 1;
  background: rgb(255, 225, 0);
  background: linear-gradient(90deg, theme('colors.sbm-blue.600'), theme('colors.sbm-blue.400'));
  transform-origin:left;
  z-index:-1;
}

span.highlight2:after {
  left: -0.5em;
  right: -0.5em;
}

.highlight2.orange {
  margin-top: 20px;
}
.highlight2.orange:after {
  background: linear-gradient(90deg, theme('colors.sbm-orange.500'), theme('colors.sbm-sun.500'));
}

.no-fullscreen::-webkit-media-controls-fullscreen-button {
        display: none !important;
}

.video-shadow::after {
  @apply rounded-xl top-0 left-0 translate-x-5 translate-y-5;
  z-index: 0;
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom right, theme('colors.sbm-orange.600/0'), theme('colors.sbm-orange.400/0.9'));
}

.dark .video-shadow::after {
  background: linear-gradient(to bottom right, theme('colors.sbm-blue.600/0'), theme('colors.sbm-blue.400/0.9'));
}